import React, { useState } from "react";
import products from "../../Assets/Product/Product";
import { Container, Pagination } from "@mui/material";
import "./Shop.css";

const Shop = () => {
  const productsPerPage = 12;
  const [page, setPage] = useState(1);
  const [category, setCategory] = useState("all");

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleCategoryChange = (selectedCategory) => {
    setCategory(selectedCategory);
    setPage(1); // Сбросить текущую страницу при изменении категории
  };

  const filteredProducts =
    category === "all"
      ? products
      : products.filter((product) => product.category === category);
  const startIndex = (page - 1) * productsPerPage;
  const endIndex = startIndex + productsPerPage;
  const displayedProducts = filteredProducts.slice(startIndex, endIndex);

  return (
    <div>
      <Container>
        <h2 className="card-head">Добро пожаловать в нашу коллекцию камней!</h2>
        <p className="card-desc">
          Наш искусственный камень - это инновационный материал, который
          сочетает в себе прочность, элегантность и функциональность. Созданный
          для использования в качестве столешниц или раковин, он обладает рядом
          преимуществ, которые делают его превосходным выбором для современных
          интерьеров.
        </p>
        <div className="categories">
          <button
            className={`categories-btn ${
              category === "all" ? "btn-active" : ""
            }`}
            onClick={() => handleCategoryChange("all")}
          >
            Все
          </button>
          {Array.from(new Set(products.map((product) => product.category))).map(
            (cat, index) => (
              <button
                key={index}
                className={`categories-btn ${
                  category === cat ? "btn-active" : ""
                }`}
                onClick={() => handleCategoryChange(cat)}
              >
                {cat}
              </button>
            )
          )}
        </div>
        <div className="card-list">
          {displayedProducts.map((product) => (
            <div key={product.id} className="card-item">
              <img
                src={product.image}
                alt={product.name}
                className="card-item-img"
              />
              <h3 className="card-item-head">
                {product.category}{" "}
                <span className="card-item-color">{product.color}</span>
              </h3>

              <a href="https://wa.me/+996778991499" className="card-item-btn">
                Заказать
              </a>
            </div>
          ))}
        </div>
        <Pagination
          count={Math.ceil(filteredProducts.length / productsPerPage)}
          page={page}
          onChange={handlePageChange}
          color="primary"
          variant="outlined"
          shape="rounded"
          size="large"
          siblingCount={1}
          boundaryCount={1}
          className="Pagination-card"
        />
      </Container>
    </div>
  );
};

export default Shop;
