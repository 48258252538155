import React, { useState } from "react";
import "./Preivew.css";
import stone1 from "../../Assets/Images/stone/stone-1.png";
import stone2 from "../../Assets/Images/stone/stone-2.png";
import stone3 from "../../Assets/Images/stone/stone-3.png";
import stone4 from "../../Assets/Images/stone/stone-4.png";
import stone5 from "../../Assets/Images/stone/stone-5.png";
import stone6 from "../../Assets/Images/stone/stone-6.png";
import stone7 from "../../Assets/Images/stone/stone-7.png";
import stone10 from "../../Assets/Images/stone/stone-10.png";
import stone12 from "../../Assets/Images/stone/stone-12.png";
import bigStone1 from "../../Assets/Images/big-stone/stone-big-1.png";
import bigStone2 from "../../Assets/Images/big-stone/stone-big-2.png";
import bigStone3 from "../../Assets/Images/big-stone/stone-big-3.png";
import bigStone4 from "../../Assets/Images/big-stone/stone-big-4.png";
import bigStone5 from "../../Assets/Images/big-stone/stone-big-5.png";
import bigStone6 from "../../Assets/Images/big-stone/stone-big-6.png";
import bigStone7 from "../../Assets/Images/big-stone/stone-big-7.png";
import bigStone10 from "../../Assets/Images/big-stone/stone-big-10.png";
import bigStone12 from "../../Assets/Images/big-stone/stone-big-12.png";
import { Container } from "@mui/material";
import { Link } from "react-router-dom";

const Preview = () => {
  const [imageUrl, setImageUrl] = useState(bigStone1);
  const [productName, setProductName] = useState(
    "Supreme [Beige Granite VB172]"
  );

  const changeImage = (imageUrl, productName) => {
    setImageUrl(imageUrl);
    setProductName(productName);
  
    // Убрать класс 'selected' у всех изображений
    const images = document.querySelectorAll(".product-box__color__images ");
    images.forEach((image) => image.classList.remove("selected"));
  
    // Добавить класс 'selected' к родителю кликнутого изображения
    const clickedImage = document.querySelector(`img[src="${imageUrl}"]`);
    if (clickedImage) {
      clickedImage.parentElement.classList.add("selected");
    }
  };

  return (
    <section className="product">
      <Container>
        <h2 className="product-head" id="collection">
          Коллекции
        </h2>
        <div className="product-box">
          <div className="product-items">
            <h3 className="product-title">
              При клике на изображения камней, вы увидите более крупные
              изображения камней. Это поможет вам лучше рассмотреть камень перед
              покупкой.
            </h3>
            <div className="product-box__color">
              <div
               className={`product-box__color__images ${imageUrl === bigStone1 ? 'selected' : ''}`}
                onClick={() =>
                  changeImage(bigStone1, "Supreme [Beige Granite VB172]")
                }
              >
                <img src={stone1} alt="" />
                <div className="checkmark"></div>
              </div>
              <div
               className={`product-box__color__images ${imageUrl === bigStone2 ? 'selected' : ''}`}
                onClick={() =>
                  changeImage(bigStone2, "Supreme [Ocean View VO171]")
                }
              >
                <img src={stone2} alt="" />
                <div className="checkmark"></div>
              </div>
              <div
               className={`product-box__color__images ${imageUrl === bigStone3 ? 'selected' : ''}`}
                onClick={() => changeImage(bigStone3, "Tempest [Dazzle FD191]")}
              >
                <img src={stone3} alt="" />
                <div className="checkmark"></div>
              </div>
              <div
                className={`product-box__color__images ${imageUrl === bigStone4 ? 'selected' : ''}`}
                onClick={() =>
                  changeImage(bigStone4, "Supreme [Latte Cream VL343]")
                }
              >
                <img src={stone4} alt="" />
                <div className="checkmark"></div>
              </div>
              <div
                className={`product-box__color__images ${imageUrl === bigStone5 ? 'selected' : ''}`}
                onClick={() =>
                  changeImage(bigStone5, "Supreme [Rotor Cloud VR322]")
                }
              >
                <img src={stone5} alt="" />
                <div className="checkmark"></div>
              </div>
              <div
                className={`product-box__color__images ${imageUrl === bigStone6 ? 'selected' : ''}`}
                onClick={() =>
                  changeImage(bigStone6, "Supreme [Arctic White VA311]")
                }
              >
                <img src={stone6} alt="" />
                <div className="checkmark"></div>
              </div>
              <div
                className={`product-box__color__images ${imageUrl === bigStone7 ? 'selected' : ''}`}
                onClick={() => changeImage(bigStone7, "Sanded[Onyx SO423]")}
              >
                <img src={stone7} alt="" />
                <div className="checkmark"></div>
              </div>
              <div
                className={`product-box__color__images ${imageUrl === bigStone10 ? 'selected' : ''}`}
                onClick={() =>
                  changeImage(bigStone10, "Supreme[Odyssey VO173]")
                }
              >
                <img src={stone10} alt="" />
                <div className="checkmark"></div>
              </div>
              <div
                className={`product-box__color__images ${imageUrl === bigStone12 ? 'selected' : ''}`}
                onClick={() =>
                  changeImage(bigStone12, "Tempest[Radiance (Shimmer) FR148]")
                }
              >
                <img src={stone12} alt="" />
                <div className="checkmark"></div>
              </div>
            </div>
          </div>
          <div className="product-items-two">
            <h3 className="product-items-two__color">{productName}</h3>
            <img src={imageUrl} alt={productName} id="bigImage" />
          </div>
        </div>
        <Link to="/shop" className="product-view__link">
          Посмотреть все цвета
        </Link>
      </Container>
    </section>
  );
};

export default Preview;
