import React from "react";
import whatsappIcon from "../../Assets/Images/whatsapp.png";
import instagramIcon from "../../Assets/Images/instagram1.png";
import "./Footer.css";
import { Container } from "@mui/material";

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <div className="footer-box">
          <div className="footer-links" id="contact">
            <h3 className="footer-head">По сайту</h3>
            <ul>
              <li>
                <a className="footer__item" href="#">
                  Главная
                </a>
              </li>
              <li>
                <a className="footer__item" href="#premium">
                  Преимущества
                </a>
              </li>
              <li>
                <a className="footer__item" href="#collection">
                  Коллекции
                </a>
              </li>
              <li>
                <a className="footer__item" href="#about">
                  О нас
                </a>
              </li>
              <li>
                <a className="footer__item" href="#contact">
                  Контакты
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-links">
            <h3 className="footer-head">Контакты</h3>
            <ul>
              <li>
                <a
                  className="footer__item"
                  href="https://wa.me/+996778991499"
                  target="_blank"
                >
                  Менеджер в СК Monolit{" "}
                  <img src={whatsappIcon} alt="WhatsApp Icon" />
                </a>
              </li>
              <li>
                <a
                  className="footer__item"
                  href="https://wa.me/+996777991499"
                  target="_blank"
                >
                  Менеджер в ТЦ МегаКомфорт{" "}
                  <img src={whatsappIcon} alt="WhatsApp Icon" />
                </a>
              </li>
              <li>
                <a
                  className="footer__item"
                  target="_blank"
                  href="https://www.google.com/maps/place/%D0%A1%D1%82%D1%80%D0%BE%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9+%D0%BA%D0%BE%D0%BC%D0%BF%D0%BB%D0%B5%D0%BA%D1%81+%22MONOLIT%22/@42.8655329,74.631117,18.27z/data=!4m6!3m5!1s0x389eb7c2cefd4f1d:0xce21dec84fef0912!8m2!3d42.8655146!4d74.6316187!16s%2Fg%2F11fp8z6q3f?entry=ttu"
                >
                  Адрес: СК Monolit 2-этаж бутик, А-5(STARON)
                </a>
              </li>
              <li>
                <a
                  className="footer__item"
                  target="_blank"
                  href="https://www.google.com/maps/place/%D0%9C%D0%B5%D0%B3%D0%B0+%D0%9A%D0%BE%D0%BC%D1%84%D0%BE%D1%80%D1%82/@42.8684675,74.6136241,16.93z/data=!4m6!3m5!1s0x389eb71adccea471:0xbab40f310d469a6d!8m2!3d42.8700448!4d74.6152763!16s%2Fg%2F11pw3116kv?entry=ttu"
                >
                  Адрес: ТЦ МегаКомфорт 4-этаж, офис-6
                </a>
              </li>
              <li>
                <a
                  className="footer__item"
                  href="https://www.instagram.com/stoleshnica.kg_?igsh=NXhnMTBpcDhhd3V6"
                  target="_blank"
                >
                  Наш инстаграмм{" "}
                  <img src={instagramIcon} alt="Instagram Icon" />
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-links">
            <iframe
              className="iframe-map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1014.1940964891218!2d74.63119866189325!3d42.865642725654595!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x389eb7c2cefd4f1d%3A0xce21dec84fef0912!2z0KHRgtGA0L7QuNGC0LXQu9GM0L3Ri9C5INC60L7QvNC_0LvQtdC60YEgIk1PTk9MSVQi!5e0!3m2!1sru!2skg!4v1709448265900!5m2!1sru!2skg"
              style={{ border: "0" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
