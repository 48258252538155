import React from "react";
import resilienceImg from "../../Assets/Images/resilience.png";
import ultravioletImg from "../../Assets/Images/ultraviolet.png";
import repairImg from "../../Assets/Images/repair.png";
import wipeImg from "../../Assets/Images/wipe.png";
import thermoImg from "../../Assets/Images/thermo.png";
import ecoImg from "../../Assets/Images/eco.png";
import "./About.css";
import { Container } from "@mui/material";

const About = () => {
  return (
    <section className="about">
      <Container>
        <h1 className="about-head" id="premium">
          Наши преимущества
        </h1>
        <div className="about-box">
          <div className="about-card">
            <img src={resilienceImg} alt="Износостойкость" />
            <h2 className="about-card__head">Износостойкость</h2>
            <p className="about-card__description">
              Обусловлена высокой плотностью и твёрдостью материала
            </p>
          </div>
          <div className="about-card">
            <img src={ultravioletImg} alt="Устойчивость к ультрафиолету" />
            <h2 className="about-card__head">Устойчивость к ультрафиолету</h2>
            <p className="about-card__description">
              Под воздействием солнечных лучей камень не меняет свои свойства и
              цвет
            </p>
          </div>
          <div className="about-card">
            <img src={repairImg} alt="Ремонтопригодность" />
            <h2 className="about-card__head">Ремонтопригодность</h2>
            <p className="about-card__description">
              Повреждённое изделие можно восстановить до состояния нового
            </p>
          </div>
          <div className="about-card">
            <img src={wipeImg} alt="Простота ухода" />
            <h2 className="about-card__head">Простота ухода</h2>
            <p className="about-card__description">
              Загрязнения удаляются влажной тряпкой или мыльным раствором
            </p>
          </div>
          <div className="about-card">
            <img src={thermoImg} alt="Термостойкость" />
            <h2 className="about-card__head">Термостойкость</h2>
            <p className="about-card__description">
              Выдерживает температуры до 130°, однако для раскалённых кастрюль и
              сковородок следует использовать подставки
            </p>
          </div>
          <div className="about-card">
            <img src={ecoImg} alt="Экологичность" />
            <h2 className="about-card__head">Экологичность</h2>
            <p className="about-card__description">
              Не содержит вредных химических соединений, безопасен для здоровья.
            </p>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default About;
