import React from 'react';
import "./Home.css"
import Slider from '../Slider/Slider';
import About from '../About/About';
import Preview from '../Preview/Preview';
import Company from '../Company/Company';
import Gallery from '../Gallery/Gallery';

const Home = () => {
    return (
        <div>
            <Slider/>
            <About/>
            <Preview/>
            <Gallery/>
            <Company/>
        </div>
    );
};

export default Home;