import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Shop from "../Page/Shop/Shop";
import Home from "../Page/Home/Home.jsx";

const App = () => {
  return (
    <React.Fragment>
        <Routes>
          <Route path="/shop" element={<Shop />} />
          <Route path="/" element={<Home />} />
          {/* Другие маршруты */}
        </Routes>
    </React.Fragment>
  );
};

export default App;
