import React from "react";
import "./Company.css";
import { Container } from "@mui/material";
const Company = () => {
  return (
    <section className="company" id="about">
      <Container>
        <div className="box">
          <h3 className="company-head">Немного о нас</h3>
          <div className="company-cards">
            <p className="company-descripton">
              Мы - компания, которая вот уже{" "}
              <span className="compny-year">15 лет</span> является лидером в
              сфере дизайна и производства уникальных изделий из искусственного
              камня. Наша миссия - превращать идеи в реальность, создавая
              дизайны, которые подчеркивают индивидуальность каждого клиента.
              <span className="company-title">
                {" "}
                Уникальные дизайны, выражающие ваш стиль
              </span>
              Мы специализируемся на разработке уникальных дизайнов столешниц и
              раковин, которые отличаются своей оригинальностью и
              индивидуальностью. В каждом изделии мы стремимся выразить вашу
              уникальную эстетику и стиль.
              <span className="company-title">Эксклюзивные раковины.</span>
              Наши эксклюзивные раковины - это не просто элементы сантехники, а
              настоящие произведения искусства, созданные с любовью к деталям.
              Мы предлагаем разнообразие форм, размеров и отделок, чтобы
              удовлетворить самые изысканные вкусы. Вдохновленные природой,
              созданные с умением
            </p>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Company;
