import React from "react";
import gallery1 from "../../Assets/Images/gallery/gallery__1.png";
import gallery2 from "../../Assets/Images/gallery/gallery__2.png";
import gallery3 from "../../Assets/Images/gallery/gallery__3.png";
import gallery4 from "../../Assets/Images/gallery/gallery__4.png";
import gallery5 from "../../Assets/Images/gallery/gallery__5.png";
import gallery6 from "../../Assets/Images/gallery/gallery__6.png";
import gallery7 from "../../Assets/Images/gallery/gallery__7.png";
import gallery8 from "../../Assets/Images/gallery/gallery__8.png";
import gallery9 from "../../Assets/Images/gallery/gallery__9.png";
import gallery10 from "../../Assets/Images/gallery/gallery__10.png";
import gallery11 from "../../Assets/Images/gallery/gallery__11.png";
import gallery12 from "../../Assets/Images/gallery/gallery__12.png";
import gallery13 from "../../Assets/Images/gallery/gallery__13.png";
import gallery14 from "../../Assets/Images/gallery/gallery__14.png";
import gallery15 from "../../Assets/Images/gallery/gallery__15.png";
import gallery16 from "../../Assets/Images/gallery/gallery__16.png";
import { Container } from "@mui/material";
import "./gallery.css"

const Gallery = () => {
  return (
   
      <div className="gallery">
        <h1 className="gallery__head">Наша галлерея</h1>
        <div className="gallery__block">
          <img className="gallery__block-img" src={gallery1} alt="" />
          <img className="gallery__block-img" src={gallery2} alt="" />
          <img className="gallery__block-img" src={gallery3} alt="" />
          <img className="gallery__block-img" src={gallery4} alt="" />
          <img className="gallery__block-img" src={gallery5} alt="" />
          <img className="gallery__block-img" src={gallery6} alt="" />
          <img className="gallery__block-img" src={gallery7} alt="" />
          <img className="gallery__block-img" src={gallery8} alt="" />
          <img className="gallery__block-img" src={gallery9} alt="" />
          <img className="gallery__block-img" src={gallery10} alt="" />
          <img className="gallery__block-img" src={gallery11} alt="" />
          <img className="gallery__block-img" src={gallery12} alt="" />
          <img className="gallery__block-img" src={gallery13} alt="" />
          <img className="gallery__block-img" src={gallery14} alt="" />
          <img className="gallery__block-img" src={gallery15} alt="" />
        </div>
      </div>
  );
};

export default Gallery;
