import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import '../../../node_modules/swiper/swiper-bundle.min.css';
import "./Slider.css"; // создайте файл Slider.css для стилей слайдера
import BgMain from "../../Assets/Images/bg-main.png";
import BgMain1 from "../../Assets/Images/bg-main-free.png";
import BgMain2 from "../../Assets/Images/bg-main-two.png";
import BgMainMobile from "../../Assets/Images/bg-mobile1.png";
import BgMain1Mobile from "../../Assets/Images/bg-mobile2.png";
import BgMain2Mobile from "../../Assets/Images/bg-mobile3.png";
import arrow from "../../Assets/Images/arrow.png";


const Slider = () => {
  const swiperRef = React.useRef(null);

  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  return (
    <div className="main">
      <Swiper
        ref={swiperRef}
        spaceBetween={50}
        slidesPerView={1}
        navigation={false} // Отключаем стандартные кнопки навигации Swiper
        pagination={{ clickable: true }}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
      >
        <SwiperSlide>
          <div className="swiper-slide">
            <h1 className="slider-text">
              Мы гарантируем - <span className="span-slide">Уникальность</span>
            </h1>
            <img src={BgMain} alt="Slide 1" className="bg-img" />
            <img src={BgMainMobile} alt="Slide 1" className="bg-img-mobile" />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="swiper-slide">
            <h1 className="slider-text">
              Мы гарантируем - <span className="span-slide">Прочность</span>
            </h1>
            <img src={BgMain1} alt="Slide 2" className="bg-img" />
            <img src={BgMain1Mobile} alt="Slide 2" className="bg-img-mobile" />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="swiper-slide">
            <h1 className="slider-text">
              Мы гарантируем - <span className="span-slide">Эстетику</span>
            </h1>
            <img src={BgMain2} alt="Slide 3" className="bg-img" />
            <img src={BgMain2Mobile} alt="Slide 3" className="bg-img-mobile" />
          </div>
        </SwiperSlide>
      </Swiper>

      <div className="custom-controls">
        <button className="btn-next" onClick={goPrev}><img src={arrow} alt="" /></button>
        <button className="btn-prev" onClick={goNext}><img src={arrow} alt="" /></button>
      </div>
    </div>
  );
};

export default Slider;
